.animal-gallery-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.animal-gallery {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.animal-gallery p {
  font-weight: 500;
}

h1 {
  margin-bottom: 20px;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 5px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  max-width: 500px;
  align-self: center;
}

.animal-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  min-width: 70px;
  height: 30px;
  text-align: center;
  width: 85px;
  transition: background-color 0.2s ease;
}

.restart-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  min-width: 70px;
  height: 30px;
  text-align: center;
  width: 85px;
  margin-top: 10px;
  transition: background-color 0.2s ease;
}

.animal-button:hover {
  background-color: #a6caec;
}

.restart-button:hover {
  background-color: #eca6a6;
}

.animals-container {
  flex: 1;
  position: relative;
  overflow: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding: 50vh 50vw;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  margin-bottom: 20px;
  max-width: 200px;
  align-self: center;
  position: absolute;
  top: 15px;
  left: 10px;
}

.color-button {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.185);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.color-button:hover {
  transform: scale(1.1);
}

.image-container {
  cursor: pointer;
  transition: all 0.2s ease;
  position: absolute;
  will-change: transform, opacity;
}

.image-container.exploding {
  opacity: 0;
  transform: scale(2);
  transition: all 0.5s ease;
}

.animal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -webkit-tap-highlight-color: transparent;
}

.footer {
  flex-shrink: 0;
  text-align: center;
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  z-index: 10;
}

.locatus {
  font-weight: bold;
}

@media (max-width: 768px) {
  .animal-gallery {
    padding: 10px;
  }

  h1 {
    font-size: 24px;
  }

  .animal-gallery p {
    font-size: 14px;
  }

  .button-container {    
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, auto);
    max-width: 500px;
  }

  .animal-button {
    min-width: 60px;
    height: 25px;
    font-size: 12px;
  }

  .color-grid {
    max-width: 160px;
  }

  .color-button {
    width: 25px;
    height: 25px;
  }

  .animals-container {
    padding: 25vh 25vw;
  }
}

@media (max-width: 490px) {
  .animal-gallery {
    padding: 10px;
  }

  h1 {
    font-size: 24px;
    margin-top: 50px;
  }

  .animal-gallery p {
    font-size: 14px;
  }

  .button-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, auto);
    max-width: 500px;
  }

  .animal-button {
    min-width: 60px;
    height: 25px;
    font-size: 12px;
  }

  .color-grid {
    grid-template-columns: repeat(8, 1fr);
    max-width: 200px;
    top: 15px;
    left: 10px;
  }

  .color-button {
    width: 20px;
    height: 20px;
  }

  .animals-container {
    padding: 20vh 20vw;
  }
}